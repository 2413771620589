.groupContent{
    margin: 20px;
    width: 100%;
}
.groupInput{
    border: none;
    padding: 10px;
    outline: none;
    
}
.groupInputContent{
    display: flex;
   padding-left: 30px;
    flex-direction: row;
    align-items: center;
    color: rgba(0, 0, 0, 0.452);
    border: 1px solid rgba(0, 0, 0, 0.452);
    border-radius: 10px;
    width: 30%;
    margin-bottom: 30px;
}
.groupTd{
    display: flex;
    flex-direction: row;
    width: auto;
    
}
.groupTd p{
    color: #000;
}
.groupTdpara{
    margin-right: 10px;
    text-transform: uppercase;
    background-color: #EF633E;
    height: 30px;
    width: 30px;
    justify-content: center;
    text-align: center;
    padding-top: 4px;
    color: white !important;
    
    
    border-radius: 50%;
}
.groupDataShow p{
    color: #0B3269;
    font-size: 15px;
}
.groupDataShowContent{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.groupsessionNuShow{
    display: flex;
    flex-direction: row;
  
}
.groupsessionNuShowDetail{
    margin:10px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 24px #00000014;
    align-items: center;
   
    padding: 10px 15px;
    border-radius: 20px;
}
.groupsessionNuShow p{
    color: #0B3269;
    font-size: 15px;
}.groupsessionNuShow h6{
    font-weight: bold;
    font-size: 20px;
}
.groupsessionNuShowbtn{
    width: 100%;
  
  
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px;
}
.groupsessionNuShowDetailIcm{
width: 50px;
height: 50px;
border-radius: 50%;
margin-right: 3px;
}
.groupsessionNuShowDetaiilCont{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.coachImg{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}
.pagination{
    display: flex;
    width: 20%;
    margin: 20px;
   
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 10px;
}
.pagination button{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) ; 
 
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
}