.ProfileContent {
    width: 100%;
    height: 100%;
    margin: 30px;
}

.ProfileHead {

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.profileImg {
    width: 200px;
    height: 200px;
    padding:5px;

    border: 5px solid #7aa146;
    border-radius: 43px;
}

.ProfileHead h6 {
    font-weight: bolder;

}

.ProfileHead button {
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border: none;
    border-radius: 10px;
    padding: 10px 10px 10px 10px;
    color: white;
    outline: none;
}

.ProfileDataContent {
    display: flex;

    align-items: center;

}

.ProfileData {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: space-between;
    margin-top: 30px;
}

.ProfileDataleft {
    display: flex;
    flex-direction: column;
    flex: 20;
    width: 100%;
    box-shadow: 0px 3px 6px #00000029;
    padding: 20px;
    border-radius: 10px;
    height: fit-content;
    align-items: center;
}

.ProfileDataRight {
    display: flex;
    flex: 80;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 10%;

}


.ProfileName h5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.ProfileName p {
    display: flex;
    flex-direction: row;

    color: #0b3467;
}

.ProfileDataRight ul li {
    list-style-type: none;
    cursor: pointer;

}

.ProfileDataRight ul {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.ProfileDataRight ul li img {
    margin-right: 5px;
}

.Activate1 {
    font-weight: bolder;

}

.ProfileDataRightContent {
    display: flex;
    flex-direction: column;
    width: 100%;
    ;
}

.Activate1 {

    cursor: pointer;
}