.mainContainer {
    /* height: 1490px; */
    width: 100%;
}

/* --------fisrt se firstContainer------------ */
.firstContainer {
    display: flex;
    justify-content: space-evenly;
    position: relative;
}

.menuesBox {
    /* white-space: nowrap; */
    display: grid;
    grid-template-columns: 0fr 0fr;
    gap: 20px;
    padding: 35px;
    width: 100%;
    position: relative;

}

.item {
    border-radius: 30px;
    width: 200px;
    height: 140px;
    display: grid;
    grid-template-columns: 87px 85px;
    grid-template-rows: 48px 111px;
    grid-row-gap: 20px;
    padding: 24px;
    position: relative;

    /* -------------- */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 24px #00000014;
    border-radius: 21px;

}

.logoImage {
    position: absolute;
    right: 30px;
    width: 30px;
    height: 25px;
}

/* ------------firstContainer=> graph------------ */
.ChartGraph {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 7px 24px #00000014;
    border-radius: 21px;
    margin: 35px 35px 40px 0px;
}

@media screen and (max-width: 1300px) {
    .firstContainer {
        flex-direction: column;
    }

    .ChartGraph {
        /* width: 650px;
        height: 350px; */
        margin-left: 30px;
    }

    .item {
        width: 100%;
    }

    .logoImage {
        position: absolute;
        right: 45px;
        width: 30px;
        height: 25px;
    }
}

.headLine {
    color: #FF3434;
    margin: 40px;
}

.selectBar select {
    border: 1px solid #E2E2EA;
    border-radius: 24px;
    margin: 30px;
    color: #B5B5BE;
    width: 140px;
    height: 50px;
    text-indent: 14px;
}

/* --------------------------------------------------------------------------------------- */

/*------------------- middle container-----------------------------  */
.middleContainer {
    margin: 20px 40px 20px 60px;
}

/* -----------------third container --------------- */
.thirdContainer {
    margin: 40px 40px 20px 60px;
}

/* ----------middle and last container having same csss------------- */
.headerLine {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

* .tables table thead tr th {
    border: 0px !important;
    margin: 0px !important;
}

* .tables table tbody tr td {
    border: 0px !important;
    border-radius: none !important;
}

.tables table {
    width: 100%;
    white-space: nowrap;
}

.tables thead tr {
    padding: 20px;
    background: #F1F1F5 0% 0% no-repeat padding-box;
    color: #B5B5BE;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-transform: uppercase;
}

.tables table tbody tr {
    border-top: 1px solid #ECECEC !important;
}

.tables {
    border: 1px solid #ECECEC !important;
    border-radius: 20px;
}

.tables table th:last-child {
    background: #F1F1F5 0% 0% no-repeat padding-box;
    border-top-right-radius: 20px;
}

.tables table td:last-child {
    border-bottom-right-radius: 20px;
}

.tables table th:first-child {
    background: #F1F1F5 0% 0% no-repeat padding-box;
    border-top-left-radius: 20px;
}

/* -------end----------- */
.img_text {
    display: flex;
    float: left;
    width: auto;
    height: 0px;
    margin-top: 4px;


}

.names {
    margin-left: 6px;

    letter-spacing: 0.1px;
}

@media screen and (max-width: 768px) {
    * .tables table thead tr th {
        border: 0px !important;
        margin: 0px !important;
    }

    * .tables table tbody tr td {
        border: 0px !important;
        border-radius: none !important;
    }

    .tables table {
        width: 100%;
        white-space: nowrap;
    }

    .tables thead tr {
        padding: 20px;
        background: #F1F1F5 0% 0% no-repeat padding-box;
        color: #B5B5BE;
        font-size: 14px;
        letter-spacing: 0.1px;
        text-transform: uppercase;
    }

    .tables table tbody tr {
        border-top: 1px solid #ECECEC !important;
    }

    .tables {
        border: 1px solid #ECECEC !important;
        border-radius: 20px;
    }

    .tables table th:last-child {
        background: #F1F1F5 0% 0% no-repeat padding-box;
        border-top-right-radius: 20px;
    }
}


.tabCnt1{
    display: flex;
    align-items: center;
    list-style-type: none;
    width: auto;
    white-space: nowrap;
}
.tab1{
    display: flex;
    font-weight: 700;
    font-size: medium;
    cursor: pointer;
    position: relative;
    color: black;
    margin: 30px 45px 0px 40px;
}
.tab1 p{
    font-weight: 500;
    font-size: small;
   
}
.tabActive1{
    color: #0E0101;
    width: auto;
    justify-content: flex-start;
   
}
.tabActive1::after{
    content: '';
    height: 3px;
    width: 110%;
    border-radius: 10px;
    background-color: #ef643ecd;
    position: absolute;
    bottom: 10px;
    left: 50%;
     margin-bottom: -17px;
    transform: translate(-50%,50%);
}