.container {
  min-height: 100vh;
  height: auto;
  width: 100%;
  background-color: #0394b80f;
}
.sidebar {
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #134481;
  min-height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  box-shadow: 3px 10px 10px 5px #13448112;
  z-index: 2;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.Sidebar2 {
  width: 0;
  height: 0;
  position: fixed;
  top: 0;
  left: 0;
}
.sidebarContainer {
  z-index: 2;
}
/* lidesign */
::-webkit-scrollbar {
  display: none;
}
.dashboardheader p {
  color: white;
}

.smartlogo img {
  padding: 10px;
}

.smarthlogo1 {
  height: 130px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.whitebg {
  height: auto;
  width: 80%;
}

.navbar {
  display: flex;
  float: right;
  right: 0;
}

.chatShape{
    width: 35px !important;
    height: 25px;
}



.bkTop {
  position: absolute;
  height: 400px !important;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to top right, #0b3467, #1a5298);
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  z-index: 0;
  overflow: hidden;
}
.foreGround {
  height: auto;
  width: 100%;
  z-index: 1;
  position: relative;
  padding-bottom: 100px;
}
.innerForeGround {
  min-height: 100vh;
  width: auto;
  height: auto;
  margin-left: 340px;
  position: relative;
}
.navname {
  display: flex;
  position: relative;
  width: auto;
  height: 130px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navname ul {
  display: block;
  list-style-type: none;
  display: flex;
  padding: 0 40px 0 0;
}
.navname ul li {
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  margin: 0 20px;
  cursor: pointer;
}
.navname ul li img {
  width: 25px;
  margin: 0 5px;
}
.bkTop {
  position: absolute;
  /* height: 430px; */
  height: 60%;
  width: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to top right, #0b3467, #1a5298);
  transform: translateY(-13%);
}
.bkTopInner {
  position: absolute;
  /* background-color: red; */
  width: 100%;
  transform: rotate(-3deg) translate(39%, -77%);
}

.content {
  background-color: white;
  border-top-right-radius: 20px;

  padding: 10px 0 0 0;
}
.profile {
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.profileImg {
  width: 100px;
  height: 100px;
  border-radius: 20px;
}

.profile .uname h2 {
  font-size: 20px !important;
  padding-top: 15px;
}
.profile .uname p {
  font-size: 13px;

  color: grey;
}
.contentitem ul li {
  font-size: 18px;
  margin: 30px 0px 0px 0px;
  padding: 5px;

  text-transform: capitalize;
  width: max-content;
  cursor: pointer;
}

.contentitem {
  padding: 10px 0px 0px 0px;
  height: 100vh;
}
.MenuToggleOpen {
  color: white;
  float: right;
  right: 0;
  top: 0;
}

.tabCnt {
  list-style-type: none;
  width: 100%;
  height: auto;
  padding: 0;
}

.tabDesign {
  width: 100% !important;
  display: block;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 !important;
}
.tabDesign > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 !important;
}
/* smartlogo */

.lidesign {
  color: white;
  background-image: linear-gradient(
    to bottom,
    #97cc4f,
    #90c14d,
    #88b64b,
    #81ac48,
    #7aa146
  ) !important;
  border-radius: 10px !important;
  padding: 5px 15px 5px 5px;
  max-width: 100%;
}
.lidesign::after {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 8px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: #97cc4f;
}

.about ul li {
  font-size: 14px;
  color: grey;
  margin: 10px !important;
}

.lidesign img {
  background-image: url("../../../../public/images/Coach/login/whitebg.svg");
  border-radius: 10px;
  margin-right: 10px;
  height: 45px;
  width: auto;
}
.contentitem ul {
  list-style-type: none;
}
.contentitem ul li {
  padding: 5px;
}
.contentitem ul li:after {
  content: "";
  padding-right: 15px;
}
.contentitem {
  padding-left: 20px;
}
.mainContainer {
  height: auto;
  min-height: 100vh;
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  background-color: red;
}
.activeside {
  max-width: 90%;
  border-right: 10px solid #7aa146 !important;
  margin-right: 10px;
  border: none;
}

/* sidebar */
.siderbarcontent {
  height: 100%;
}
/* contentitem */
.dashboardheader {
  color: white;
  margin: 50px 0 20px 0;
}
.dashboardcontainer {
  margin: 0 30px 0 0;
  background-color: white;
  display: flex;
  width: auto;
  height: auto;
  border-radius: 30px;
  box-shadow: 0px 10px 20px #0000000d;
  overflow-x: hidden;
}

.leftcontainer {
  padding: 30px;
  flex: 50;
}

.uppercontainer {
  margin: 10px;
  display: flex;
  flex-direction: row;
}
.lowercontainer {
  margin: 10px;
  display: flex;
  flex-direction: row;
}
.sessioncontainer {
  box-shadow: 3px 10px 10px 5px rgba(128, 128, 128, 0.456);
  border-radius: 20px;
  margin: 0px 0px 0px 10px;
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;
}
.clientcontainer {
  box-shadow: 3px 10px 10px 5px rgba(128, 128, 128, 0.456);
  border-radius: 20px;

  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
}
.earningcontainer {
  box-shadow: 3px 10px 10px 5px rgba(128, 128, 128, 0.456);
  border-radius: 20px;
  margin: 0px 0px 0px 10px;

  width: 200px;
  display: flex;
  align-items: center;
  height: 200px;
}
.cochingcontainer {
  box-shadow: 3px 10px 10px 5px rgba(128, 128, 128, 0.456);
  border-radius: 20px;
  display: flex;
  align-items: center;
  width: 200px;
  height: 200px;
}

.rightcontainer {
  flex: 50;
  box-shadow: 3px 10px 10px 5px rgba(128, 128, 128, 0.456);
  border-radius: 20px;
  margin: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 400px;
}
.muiInput {
  border-radius: 20px;
}
.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  transform: translateY(100px);
  /* background-color: red; */
}

.toggleButton {
  outline: none;
  top: 0 !important;
  z-index: 1;
  position: fixed;
}

/*  media query  */

@media screen and (max-width: 600px) {
  .toggleButton {
    top: 0 !important;
    z-index: 1;
    position: fixed;
    background-color: #7aa146;
    color: white;
    border-radius: 5px;
    margin: 10px;
    border: none;
    border-radius: 5px;
    padding: 5px;
  }
  .HideButton {
    outline: none;
    margin-left: 320px;
  }
  .innerForeGround {
    margin-left: 0;
  }
}
