.dashcontainer {
    height: 100%;
    width: 100%;

}

.upgradeTap {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../../../public/images/Coachee/statistic.png");
    background-size: cover;
    border-radius: 20px;
    color: white;
    margin: 20px 20px 20px 20px;
    padding: 20px 20px 30px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
}

.upgradeTxt h4 {
    font-weight: 500;
}

.upgradeTxt h3 {
    font-weight: 300;
}

.upgradeTxt p {
    color: white;
}

.upgradeButton Button {
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    color: white;
    padding: 10px 20px 10px 20px;
    text-transform: capitalize;
    display: flex;

}

/*    */
.UpcomingContainer {
    margin: 20px 20px 20px 20px;
    padding: 20px 20px 30px 20px;
}

.Upcoming {

    display: flex;
    justify-content: space-between;
    position: relative;

}

.ViewAl span {
    color: rgb(65, 179, 225);
    font-weight: bold;
    cursor: pointer;

}

.UpcomingP {
    position: relative;


}

.UpcomingP p {
    color: black;
    font-size: 20px;
    margin-left: 10px;
    font-weight: bold;
}

.UpcomingP::after {
    content: '';
    position: absolute;
    margin-right: 5px;
    top: 0;
    height: 30px;
    width: 5px;
    border-radius: 8px;
    background-color: #F89C72;
}

.ShowLecture {
    margin-top: 40px;
    display: grid;
    gap: 20px;
    border-radius: none !important;
}

.ShowLecture div {
    grid-row-start: 1;
    grid-row-end: 4;
}

.coaching {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 30px;
margin: 10px;

}

.coachingTxt {
    margin-top: -60px;
    box-shadow: 0px 10px 20px #0000000D;
    background-color: white;
    border-radius: 20px;
    padding: 15px;
    margin-left: 10px;
    min-width: 230px;
    width: 245px;
    max-width: 300px;
    cursor: pointer;
height: 160px;

}

.coachEmail {
    display: flex;
    justify-content: space-between;
    width: 70px;
  
    

}

.coachingTxt p {
    color: black;
    font-weight: bold;
}

.coachEmail span {
    color: #0C5899;
    margin-top: 10px;
    font-weight: bold;
    
  
   
}
.coachDetailsContent{
    display: grid;
    grid-template-columns: repeat(4,1fr);
   
}
.coachtime span {
    color: grey;
}

.ShowLecture {
    font-size: 13px;
}

.coachtime {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.coachButtonJoin {
    background-image: linear-gradient(to bottom, #91c14d90, #91c14d90, #91c14d90, #91c14d90, #91c14d90) !important;
    color: white;

    text-transform: capitalize;

}
.coachButtonJoin1{
    background-image: linear-gradient(to bottom, #91c14de6, #91c14de2, #91c14ddc, #91c14df3, #91c14d90) !important;
    color: white;

    text-transform: capitalize;
}
.coachButtonJoin1 a{
    color: white;
}
.category {
    display: flex;
    justify-content: space-between;
    color: #FF5733;
}

.category {
    font-weight: bold;

}
.coachTimeZone{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.coachTimeZone p{
    color: #81ac48;
}
/*  My Report  */
.LearnMore {
    margin: 20px 20px 0px 20px;
    padding: 20px 20px 0px 20px;
}

.ConsultRead {
    color: #97cc4f;
    font-size: 15px;
}

.qnaContainer {
    margin: 20px 20px 20px 20px;
    padding: 20px 20px 20px 20px;
    background-color: #97cc4f;
    border-radius: 10px;
    color: white;
}

.qnaContainer p {
    color: white;
}

.qnaImgContent {
    display: flex;
    justify-content: flex-start;
    align-items: center;

}

.qnaImgContent h4 {
    color: white;
    margin-left: 20px;
    font-weight: bold;
    font-size: 15px;
}

.qnaQuest {
    margin: 20px 20px 20px 20px;
    padding: 20px 20px 20px 20px;
}

.qnaQuest ul {
    list-style-type: none;
}

.qnaLi {
    display: flex;
    width: 30%;
    justify-content: space-between;
    cursor: pointer;
    position: relative;
}

.tabActive1 {

    color: #0e0101;
    justify-content: flex-start;
}

.cartContent input:focus {
    border: none;
}

.tabActive1::after {
    content: '';
    height: 3px;
    width: 45%;
    border-radius: 10px;
    background-color: #97cc4f;
    position: absolute;
    bottom: -10px;
    transform: translate(-80%, 50%);
}

.tabActive2::after {
    content: '';
    height: 3px;
    width: 40%;
    border-radius: 10px;
    background-color: #97cc4f;
    position: absolute;
    bottom: -10px;
    transform: translate(-70%, 50%);
}

/*      Pop up Screen styling */
.BoxStyle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    background-color: white;
    box-shadow: 24;
    padding: 20px 20px 20px 20px;
    display: flex;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
}

.HandleCloseIcon {
    font-size: 40px;
}


.HandleCloseIcon {
    font-size: 40px !important;
    padding: 4px;
    background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E);
    border: 4px solid white;
    color: white;
    border-radius: 100%;
}

.BoxStyle span {
    cursor: pointer;
    position: absolute;
    margin-top: -10px;
    margin-right: -20px;
    outline: none;
    border: none;
    width: 10%;
    top: 0;
    float: right;
    right: 0;

}

.UpgradeDiv {

    display: flex;
    justify-content: space-between;
    align-items: center;

}

.UpgradeDiv img {


    width: 100px;
    height: 100px
}

.UpgradeText {
    color: black;
    align-items: center;


}

.TotalPay {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: black;




}

.TypoButton {
    width: 100%;
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px 5px;
    margin-bottom: 20px;
    outline: none;
}
.ScheduledAppContent {
    width: 100%;
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px 5px;
    margin-bottom: 20px;
    outline: none;
    margin-top: 30px;
}
.ScheduledAppContent p{
    color: white;
}
.TypoButtonBtn{
    color: #7aa146;
    background-color: white;
    padding: 10px;
    border-radius: 20px;
    border: none;
}

.TypoButton1 {
    width: 100%;
    /* font-weight: bold; */
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E);
    border-radius: 10px !important;
    border: none;

    color: white !important;
    padding: 10px;  
    margin-bottom: 20px;
    outline: none;
}

.typoDesc {
    width: auto;
    min-width: 400px;
    padding: 10px;

}
.typoDesc h6{
    color:black;
    font-weight: bold;
}

/*  new design  */
.Recommond {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    min-height: 30vh;


}

.coachingImg {
    width: 260px;
    height: 200px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.recommendContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.coachImg {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;
    margin-right: 20px;

}

.coachIcon {
    width: 100px;
    height: 100px;
    background-color: #0C5899;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    color: white;
    margin-right: 20px;
}

.recommendLeft {
    flex: 50;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.recommendRight {
    flex: 50;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
}

.recommendRight button {
    width: 45%;

}

.dataIcoCoach {
    color: #0C5899;
}

.dataIcoCoach span {
    margin-right: 2px;
}

.TypoButton span {
    margin-right: 5px;
}

.comPowStyle {
    display: flex;
    flex-direction: row;
}

.comPowStyles {
    margin: 10px 10px 0px 0px;
    background-image: linear-gradient(to bottom, #ee7158, #f36744, #f25f32, #e77a53, #ed5436);

    padding: 5px 20px 5px 20px;
    color: white;
    border-radius: 10px;
}
.DashForRecom{
    margin:20px 20px 20px 20px ;
}
.widPara {
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: top;
    font-size: 14px;
    /* justify-content: center; */
}

.comPowDesign {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.UserListContent h4::after{
    
    content: '';
    position: relative;
  margin-right: 10px;
    top: 0;
    height: 30px;
    width: 5px;
   float: left;
   left:0;
    border-radius: 8px;
     background-image: linear-gradient(to bottom, #F89C72, #f5ad8c, #f19970, #f17d47, #ee6626) ;

    }
    .UserListContent{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
       
    }
    .UserListContent h6{
        color: #2b6bb9;
        font-size: bold;
        cursor: pointer;
    }
.dataColors {

    color: black;
}

.cardDesignSelect {
width: 80%;
 

   
   

}

.cardDesignSelect p {
    text-align: left;
    color: black;
  
}



.cinfoValue {
    display: flex;
    flex-direction: column;
}

.MenuItems p {
    color: black;
  display: flex;
  flex-direction: row;


}
.nextButtonArrow{
    background-color: #7aa146;
   color: white;
   padding:3px ;
   border-radius: 50%;
   
}
.prevButtonArrow{
    background-color: #7aa146;
   color: white;
   padding:3px ;
   border-radius: 50%;
   
}
.MenuItemsIC{
    color: #184E92 !important;
    font-size: 15px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.dayspara{
    color: black;
}

.MenuItems {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.482);

}
.showAvailCoach{
    display: flex;
    align-items: center;
}
.Description p{
    text-decoration: none;
    font-weight: 400;
    font-size: medium;
}
.ProgramDate p{
    color: #0C5899;
    font-weight: 400;
    font-size: medium;
}
.UpcomingPList{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    
  
    cursor: pointer;
}
.UpcomingPListRecommendt{
    display: flex;
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    width: 40%;
    
  
    cursor: pointer;
}
.UpcomingPListRecommendt p{
    color: black;
}
.UpcomingPListLeft{
    display: flex;
    flex:60;
    position: relative;
    flex-direction: row;
    justify-content: space-between;

}
.introvideo{
    width: 30%;
    height: 40%;
    border-radius: 20px;
}
.UpcomingPListRight{
    display: flex;
    flex:40;
    position: relative;
    flex-direction: row;
    justify-content: end;
    align-items: start;
  
}
.UpcomingPList p{
    color: black;
}
.setliactive {
    color: black;
    font-weight: bold;
    width: auto;
    position: relative;
}

.tab1{
    position: relative;
}
.PorgrmaTypes{
    outline: none;
    padding: 5px;
    border-radius: 10px;
    border: 2px solid #ed5436;

}

.setliactive::after{
    content: '';
    height: 3px;
    width: 110%;
    border-radius: 10px;
    background-color: #ef643ecd;
    position: absolute;
    bottom: 10px;
    left: 50%;
     margin-bottom: -17px;
    transform: translate(-50%,50%);
}

.displaystartTime{
    margin-top: 10px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: fit-content;
    
    min-height: 50px;
    
}
.displaystartTimepara{
   border:3px solid #ed5436;
    color:  black;
    padding: 5px;
    margin: 5px;
    border-radius: 10px;
    cursor: pointer;

}
.displaystartTimepara:active{
    color:white;
    background-image: linear-gradient(to bottom, #ee7158, #f36744, #f25f32, #e77a53, #ed5436);
}
.displaystartTimepara1{
    color:white;
    background-image: linear-gradient(to bottom, #ee7158, #f36744, #f25f32, #e77a53, #ed5436);
}
.cursor{
    cursor: pointer;
}
.activeDay{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) ; 
    padding: 5px;
    border-radius: 10px;
    color: white;
    text-align: center;
}
.activeDay p, .activeDay h6{
    color: white;
}
.coachshowImg{
    width: 100px;
    height: 100px;
    border-radius: 20px;
    opacity: 0.5;
    padding: 10px;
}
.programActive{
opacity: 1;
cursor: pointer;
}
.programActivePara{
    color: #0C5899;
}
.DeleteCard {
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) ; 
    padding: 5px;
    border-radius: 10px;
    color: white;
    text-align: center;
    margin-right: 20px;
    border: none;
}
.DeleteCard1 {
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) ; 
    padding: 10px;
    border-radius: 10px;
    color: white;
    text-align: center;
    margin-right: 20px;
    border: none;
    width: 50%;
}
.DeleteCard p{
    color: white;
}
.DeleteCardContent{
    display: flex;
    flex-direction: row;
}
.DeleteCardBtn{
    display: flex;
    flex-direction: row;
}
.pagination{
    display: flex;
    width: 20%;
    margin: 20px;
    justify-content: space-evenly;
    padding: 10px;
    border-radius: 10px;
}
.pagination button{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) ; 
 
    border-radius: 10px;
    color: white;
    padding: 5px 10px;
    border-radius: 10px;
    outline: none;
    border: none;
}
.pagination button:disabled,
button[disabled]{
    opacity: 0.7;
}
.DeleteCardBtnRed{
    margin-right: 10px;
    border-radius: 10px;
    padding: 10px;
    color: white;
    background-color: #EF633E;
    border: none;
    outline: none;
}
.DeleteCardBtnBlue{
    margin-right: 10px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    outline: none;
    background-color: #154888;
    color: white;
}
.DeleteCardOption p{
    color: black;
}
.DeleteCardOption{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.DeleteCardImg{
    width: 50px !important;
    height: 30px !important;
}
.DisplaytextField{
    display: flex;
    flex-direction: row;
   
}
.Inputfield{
    margin-left: 10px;width: 100%;
}