.AdminManage{
    margin: 20px;
}
.adminData{
    display:flex;
    flex-direction: row;
    
    justify-content: space-between;
}
.adminDataclr{
   color: #0B3269;
   font-size: 13px;
}
.adminDataIcon{
    color: #EF633E;
}
.adminDatacl{
    color: #436913;
}
.AdminManageHead button{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border: none;
    border-radius: 10px;
    padding:10px;
    color: white;
    outline: none;
}
.AdminManageHead{
    display: flex;
    flex-direction: row;
    width: 100%;
justify-content: space-between;
margin-bottom: 20px;
}
.Inputfield{
    padding: 5px;
}