.container{
    margin:20px;
    width: 100%;
}
.setContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  
}
.leftContent{
    display:flex;
    flex: 50;
    flex-direction: row;
    width: 50%;
    width: 100%;
    margin: 10px;
}

.rightContent{
    display:flex;
    flex: 50;
    box-shadow: 0px 7px 24px #00000014;
   border-radius: 20px;
      width: 100%; 
       margin: 10px; 
}
.TotalUsers{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-right: 10px;
      margin-bottom: 10px;
   box-shadow: 0px 7px 24px #00000014;
   border-radius: 20px;
}
.UsersContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   margin-bottom: 10px;
   padding: 10px;
}
.TotalUsersContent{
    width: 90%;
    margin-left: 10px;
    margin-bottom: 10px;
}
.redArrowDown{
    color: red !important;
}
.UsersContent p span{
color: #97cc4f;
}
.UsersContent p  p{
font-size: 10px;
margin-bottom: -10px;
}
.UsersContent h3{
    color: #0b3467;
    font-weight: bold;
}

.UserListContent h4::after{
    
    content: '';
    position: relative;
  margin-right: 10px;
    top: 0;
    height: 30px;
    width: 5px;
   float: left;
   left:0;
    border-radius: 8px;
     background-image: linear-gradient(to bottom, #F89C72, #f5ad8c, #f19970, #f17d47, #ee6626) ;



}
.sessiontable  th{
    height: 50px;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    /* border-left: 1px solid white; */
   border-collapse: collapse;
   text-align: center;
   /* background-color: white; */
   white-space: nowrap;
}
.sessiontable td{
height: 50px;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid white;
    border-top: 1px solid white;
    /* border-left: 1px solid white; */
   border-collapse: collapse;
   text-align: start;
   /* background-color: white; */
   white-space: nowrap;
}
.sessiontable{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
}
.img_textt{
    display: flex;
    justify-content: center;
    /* flex-direction: row; */
   float: left;
   width: auto;
   height: 0px;
   margin-top: 10px;
  
  }
  .namess{
    display: flex;
    flex-direction: column;
   
    margin: -10px 0px 0px 5px;
    text-align: left;
  }
  .img_textt img{
   width: 27px;
   height: 28px; 
   margin: -8px 5px 0px 15px;
  }

.coachImg{
    border-radius: 100%;
    width:40px;
    height: 40px;
    margin-right: 10px;
}
.coachImg1{
/* border-radius: 100%; */
font-size: 30px;
width:35px;
    height: 35px;
margin-right: 15px;
margin-left: 5px;
}
.sessiontable td{
   
    align-items: flex-start !important;
    font-size: 16px;
}
.CoachTable{
    margin-bottom: 20px;
}
.UserListContent{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
}
.UserListContent h6{
    color: #2b6bb9;
    font-size: bold;
    cursor: pointer;
}
.contactUsContent{
    width: 100%;
    padding: 20px;
    border-radius: 10px ;
    box-shadow: 0px 7px 24px #00000014;
    margin:10px 10px 50px 10px;
}
.ContactUsImg{
    width: 10%;
    height: 100%;
margin-right: 20px;
}
.ContactUsData td{
    text-align: left;
    padding-left: 20px;
    width: 100px;
    overflow-y: scroll;
}
.ContactUsData td p{
    width: 100px;
}
.ContactUsBtn{
    font-size: 10px;
    outline: none;
    border:none;
    color:#0b3467;
    font-weight: bolder;
}
.ContactUsBtn :hover{
    text-decoration: underline;
}
.thisData{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.thisMsg p{
    padding:10px;
    color: black;
   border:2px solid #97cc4f;
   border-radius: 20px;
   width: 400px;
   min-height: 200px;
}
.ContactUsImg{
    width: 30px;
}

.AccStatus0{
   
   text-align: left;
   color: rgb(228, 184, 25);
 
}
.AccStatus1{
   
    text-align: left;
    color: rgb(205, 205, 52);
}
.AccStatus2{
   
    text-align: left;
    color:#7AA146;
}
.AccStatus3{
   
    text-align: left;
    color: #ff9822;
}
.AccStatus4{
   padding-left: 20px;
    text-align: left;
    color: #FF2222;
}
