*{
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
}
body{
  min-height: 100vh;
  max-width: 1920px;
  margin: auto;
  width: 100%;
}
