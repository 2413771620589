.ProContainer {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.CardContainer {
  display: flex;
  gap: 15px;
}

.SingleCard {
  position: relative;
  height: 15rem;
  border-radius: 25px;
  width: 15rem;
  display: flex;
  justify-content: center;
}

.DetailContainer {
  position: absolute;
  bottom: 20px;
  width: 90%;
  background-color: white;
  padding: 10px;
  border-radius: 25px;
  box-shadow: 0px 10px 20px #0000000d;
}

.ProImage {
  height: 80%;
  width: 100%;
  border-radius: 25px 25px 0 0;
}
