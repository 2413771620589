.ChatContainer{
    margin: 20px;
    width: 100%;
}
.Chatparts{
    width: 100%;
  
    display:flex;
    flex-direction: row;
}
.ChatpartsLeft{
    width: 100%;
  min-height: 70vh;
  height: 70vh;
  overflow-y: scroll;
    display: flex;
    flex: 30;
  flex-direction: column;
}
.ChatpartsRight{
     width: 100%;
    display: flex;
    flex: 70;
   
   justify-content: flex-end;
 flex-direction: column;
}
.Chathead{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Chathead h4{
    font-weight: bold;
}
.Chathead p{
    color: #3a80d5;
    cursor: pointer;
}
.Chathead button{
       background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 border: none;
 border-radius: 10px;
 padding: 0px 10px 0px 10px ;
 color: white;
 outline: none;
}
.UpgradeDiv{
 
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.typoDesc{
    width: auto;
  min-width: 400px;
  padding: 10px;
}
.BoxStyle{
     position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: white;

  box-shadow: 24;
  padding:20px 20px 20px 20px ;
  display:flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
}

.HandleCloseIcon{
    font-size: 40px !important;
    padding:4px;
     background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E) ;
    border: 4px solid white;
    color: white;
    border-radius: 100%;
}
.BoxStyle span{
    cursor: pointer;
 position: absolute;
 margin-top: -10px;
 margin-right: -20px;
    outline: none;
    border: none;
     width: 10%;
  top:0;
  float: right;
  right: 0;
 
}
.Inputfield{
    width: 100%;
}
.InputTextfield{
    width: 100%;
    margin-left: 2px;
}
.TypoButton{
    width: 100%;
     font-weight: bold;
text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
  border-radius: 10px !important;
  border:none;
  color: white !important;
  padding:10px;
  margin-bottom: 10px;
  outline: none ;
text-align: center;
cursor: pointer;
}
.TicketDesc{
    margin: 10px;
   box-shadow: 0px 7px 24px #00000014;
   background-color: #00000014;
   border-radius: 20px;
   padding: 5px;
   cursor: pointer;
}
.TicketDesc p{
    color: black;
}
.TicketDesc span{
    color: rgba(0, 0, 0, 0.378);
    font-size: 15px;
}
.SendMsgButton{
    width: 100%;
    border: 2px solid rgba(0, 0, 0, 0.373);
    border-radius: 10px;
}

.DisplayMsg{
    width: 100%;;
    display: flex;
    flex-direction: column;
 

}
.ChatPage{
    height: 60vh;
    overflow-x:scroll ;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}
.DisplayMsg p{
    color: black;
    background-color: rgba(128, 128, 128, 0.195);
    padding: 10px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.SendMsgButton button{
    background-color: white;
    border: none;
    color: #81ac48;
outline: none;
padding: 10px 0px 10px 0px;

}
.SendMsgButton input{
    width: 96%;
    border: none;
    margin-left: 3px;
}
.SendMsgButton input:focus{
   
    border: none;
    outline: none;
}

.msgTextLeft{
    display: flex;
   
    width: auto;
    justify-content: flex-end;
   
    right:0;
}
.msgTextLeft p{
    width: auto;
}
.msgTextRight{
    display: flex;
   
    width: auto;
    justify-content: flex-start;
   
    right:0;
}
.msgTextRight p{
    width: auto;
}