.recorded {
 width:100%;
 display: flex;
 justify-content: space-between;
 align-items: flex-start;
}
.EditRightData{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    
}
.EditLeft{
    display: flex;
width: 100%;
    flex-direction: row;
    justify-content: left;
    align-items: center;
   
}
.EditLeft img{
    width:150px;
    height: 150px;
    border-radius: 100%;
    margin-right: 20px;
}
.EditLeftDataP1{
    color: #134481;
font-weight: bolder;
}
.EditLeftDataP1 span{
    color: #13448184;
  
    border-radius: 100%;
    margin-right: 10px;
}
.EditLeftData h5{
    text-transform: capitalize;
}
.EditLeftDataP2 span{
    color: #fff;
    background-color: #EF633E;
    border-radius: 100%;
    margin-right: 10px;
    padding: 2px;
}
.EditLeftDataP2{
    color: #EF633E;
font-weight: bolder;
}
.EditDescription{
    width: 100%;
    min-height: 30vh;
}
.EditDescription p{
    font-size: 13px;
}
.EditRightBtn1{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 
    padding: 10px;
    border-radius: 10px;
    border: none;
  
    color: white;
}
.EditRightBtn2{
    background-color: #E01A1A;
    padding: 10px;
    border-radius: 10px;
    border: none;
    margin-left: 20px;
    color: white;
}
.recordContainer{
    width: 50%;
    display: flex;
    flex-direction: column;
}

.coachImg{
    border-radius: 100%;
    width:40px;
    height: 40px;
    margin-right: 10px;
}
.recordContainer ul{
    list-style-type: none;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.AddBtn{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 
    padding:5px 10px 5px  10px ;
    color:white;
    border-radius: 10px;
    border: none;
    outline: none;
}
.RemoveBtn{
    background-color: rgb(156, 8, 8);
    padding: 5px;
    color: white;
    border-radius: 10px;
    border: none;
    outline: none;
}
.progProfile{
     width: 200px;
     border-radius: 20px;
      height: 200px;
      border: 1px solid rgba(128, 128, 128, 0.4);

}
.IconImg{
    position: absolute;
    float: right;
    bottom: 0;
    margin-right: -200px;
    margin-bottom: -10px;
}
.MainImgPre{
      width: 200px;
     border-radius: 20px;
      height: 200px;
      border: 1px solid rgba(128, 128, 128, 0.4);
}
.MainImg{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.chapterContent{
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(128, 128, 128, 0.445) ;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;position: relative;
}
.chapterDisp{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center ;
}
.TextAreaField{
    margin-right: 10px;
    margin-bottom: 20px;
}

.AddFileBtn{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
   
}
.AddFileBtn1{
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 
    color: white;
    border-radius: 100%;
    margin:10px;
    font-size: 30px;
}
.Inputfield{
    width: 100%;
    
}
.submitBun{
    width: 80%;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
 
    padding: 10px;
    color:white;
    border: none;
    outline: none;
    justify-content: center;
    border-radius: 10px;
}
.LectPro{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.multiFmIcon{
    position:absolute;
    float: right;
    right: 0;
    top:0;
    margin-top: -10px;
    margin-right: -10px;
    padding: 2px;
   color:white;
     background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
  border-radius: 100%;
   outline: none;
   cursor:pointer;
    
}
.TextFieldLabel{
    color:rgb(53, 177, 227);
    font-size: 10px;
    margin-bottom: -10px;
    background-color: white !important;

    
}
.Inputfile{
    border: 1px solid rgba(128, 128, 128, 0.4);
    width: 100%;
    padding: 12.5px;
    border-radius: 5px;
}

/*                Display     program       */
.container{
    min-height: 100vh;
    height: 100%;
    
}
.EditData{
    text-transform: uppercase;
    background-color: #134481;
    font-size: 60px;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    margin-right: 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

}
.groupContenter { text-align: left ;
justify-content: left;
background-color: red;
}

.programContent{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    min-height: 100vh;
    height: 100% ;
   margin-right: 20px;
   margin-bottom: 20px;
   
 
}
.DisplayProg{
    display: grid;
    grid-template-columns: (4);
     margin-right: 20px;
    margin-bottom: 50px;
}
.progContent{
 display: flex;
    flex-direction: row;
    position: relative;
    cursor: pointer;
    margin-bottom: 30px;
 
}
.programText{
    position:absolute;
    background-color: white;
    margin-top: 130px;
  width: 230px;
box-shadow: 0px 3px 6px #00000029;
padding: 10px;
    border-radius: 20px;
  margin-left: 10px;
}
.progContent img{
border-radius:20px ;
width: 250px;
max-width: auto;
height: 200px;
}
.programDate{
    color: #7aa146;
}
.programDateIcon{
   font-size: 30px;
   margin-right: 3px;
}
.Filetext{
 border:  1px solid rgba(0, 0, 0, 0.377);
 border-radius: 7px;
 margin-top: 10px;
 margin-bottom: 10px;
 padding: 20px;
 display: flex;
 flex-direction: column;
 align-items: center;
}
.Filetext h6{
    color:black;
    font-weight: bold;
}
.FileIcon{
    color: #afe36b;
    font-size: 50px;
    margin-bottom: 10px;
}
.Filep{
    font-size: 12px;
    text-align: center;
}
.coachData{
    display: grid;
    grid-template-columns: repeat(4,1fr);
   
}
.coachContainer{
   display: flex;
   flex-direction: column;
   margin-top: 30px;
}
.coachImg2{
    width: 240px;
    height: 180px;
    margin-right:20px;
    
}

.coachName{
    background-color: white;
    width: 200px;
    margin-top: -40px;
    margin-left: 20px;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    
}
.coachName p{
    color: #2c79d7;
    font-weight: bold;
}
/*  group Design */

.groupInfo1{
display: flex;
width: auto;
height: auto;
text-align: left;
}

.groupIc{
  color: white;
  background-color: #EF633E;
  padding: 5px;
  border-radius: 100%;
  margin-right: 20px;
 
  text-transform: uppercase;

}
.assignedCoach{
    text-align: left;
}
.groupIc1{
    width: 30px;
    height: 30px;
    margin-right: 20px;
    border-radius: 100%;
}
.groupIc2{
    width: 50px;
    height: 50px;
    margin-right: -20px;
    border: 1px solid white;
    background-color: white;
    border-radius: 100%;
}
.groupIcTd{
    justify-content: left;
    text-align: left;
    margin-left: 30px;
}
.editIcon img{
    width: 30px;
    height: 30px;
    margin: 5px;
}

.GroupInfoContent{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.GroupInfoRight{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.groupData{
    display: flex;
    flex-direction: column;
    margin: 10px;
    box-shadow: 0px 3px 6px #00000029;
    padding: 10px;
    border-radius: 13px;
    width: 150px;
}
.groupData span{
    display: flex;
color: #134481;
font-weight: bolder;
text-align: center;
    flex-direction: row;
    padding-left: 3px;
}
.groupData span p{
    background-color: #EF633E;
    padding: 5px;
    margin-right: 5px;
    color: white;
    border-radius: 100%;
}
.groupData p{
    color: #2c79d7;
    font-size: 10px;
    padding-left: 3px;
}
.groupButton{
    display: flex;
    flex-direction: row;
 justify-content: flex-end;
    width: 100%;
    
}
