
.containerTop {
   width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between  ;
    /* background-color: red; */
  
    
}
.topContent{
  
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
  
}
.topImg{
    display: flex;
    flex-direction: row;
 align-items: center;
}
.coachName{
    text-transform: capitalize;
    margin-left: 30px;
}
.coachImg{
    border-radius: 100%;
    width: 100px !important;
    height: 100px !important;
    color: #084a99;
}

.coachName h6{
    display: flex;
    flex-direction: row;
}
.activeName{
    color: #7AA146;
    display: flex;
    font-size: 13px;
    flex-direction: row;
    align-items: top;
   
}
.activeName1{
    color: #E01A1A;
    display: flex;
    font-size: 13px;
    flex-direction: row;
    align-items: top;
}

.activeName1 p::after{
    content: "";
    height: 10px;
    width: 10px;
    background-color: white;
   border-radius: 100%;
   border:2px solid #E01A1A;
    border-radius: 50%;
    display: inline-block;
    margin-left: 20px;
    margin-right: 5px;
   
}
.activeName p::after{
    content: "";
    height: 10px;
    width: 10px;
    background-color: white;
   border-radius: 100%;
   border:2px solid #7aa146;
    border-radius: 50%;
    display: inline-block;
    margin-left: 20px;
    margin-right: 5px;
   
}
.activeName2{
    color: #FFCC00;
    display: flex;
    font-size: 13px;
    flex-direction: row;
    align-items: top;
}

.activeName2 p::after{
    content: "";
    height: 10px;
    width: 10px;
    background-color: white;
   border-radius: 100%;
   border:2px solid #FFCC00;
    border-radius: 50%;
    display: inline-block;
    margin-left: 20px;
    margin-right: 5px;
   
}
.emailIcon{
    font-size: 13px ;
    color: #0d54aa;
    
}
.topTotalSect{
    display: flex;
    flex-direction: row;
    color:#0B3467;
    
}
.totalsec{
    width: 150px;
    height: 70px;
    color:#084a99;
   
   margin: 10px;
   padding: 0px 10px 0px 10px ;
   box-shadow: 0px 7px 24px #00000014;
   border-radius: 20px;
}
.totalsec span{
    font-size: 10px;
    color:#589cee;
}
.totalsec h5{
    font-weight: bolder;
}
.topBtn1{
    background-color: #E01A1A;
    color: white;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 10px;
}
.topBtn2{
    margin-right: 5px;
}



.BoxStyle{
    position: absolute;
 top: 50%;
 left: 50%;
 transform: translate(-50%, -30%);
 width: auto;
 background-color: white;
 box-shadow: 24;
 padding:20px 20px 20px 20px ;
 display:flex;
 border-radius: 20px;
 flex-direction: column;
 align-items: center;
}

.HandleCloseIcon{
    font-size: 40px;
}


.HandleCloseIcon{
    font-size: 40px !important;
    padding:4px;
     background-image: linear-gradient(to bottom, #174C8E, #184E92, #154888, #13437F, #174C8E) ;
    border: 4px solid white;
    color: white;
    border-radius: 100%;
}

.BoxStyle span{
    cursor: pointer;
background-color: white;
border-radius: 100%;
 position: absolute;
 margin-top: -10px;
 margin-right: -15px;
    outline: none;
    border: none;

  top:0;
  float: right;
  right: 0;
 
}

.upgradeButton button{
    text-transform: capitalize;
    background-color: #7aa146;
    color: white;
    padding:7px 10px 7px 10px;
    border-radius: 10px;  outline: none;
}
.upgradeBtn{
   
    background-color: white ;
    color: #7aa146;
    margin:5px;
     border-radius: 5px;
   
}
.upgradeButton Button:hover{
  background-color: #7aa146;
    color: white;
}
.typoDesc{
    width: auto;
  min-width: 400px;
  padding: 10px;
}
.profile{
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
}
.profileImg{
    border-radius: 100%;
    width: 50px !important;
    height:50px !important;
    color: #084a99; 
    margin-right: 20px;
}
.ProgramProp{
    
    display: flex;
    flex-direction: column;
}
.onlinePro h6{
    color:black;
    display: flex;
    flex-direction: row;
    
}
.onlinePro p{
    color: #0B3467;
}
.onlinePro h6 p{
    margin-left: 10px;
}
.onlineProic1{
    background-color: #084a99;
    color: white;
    border-radius: 5px;margin-right: 5px;
}
.onlineProic2{
    background-color: #ed3f6e;
    color: white;
    border-radius: 5px;margin-right: 5px;
}
.onlinePro{
    display: flex;
    flex-direction: column;
    color: black;
}
.onlineProBtn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
   
  

}
.onlineProBtn button{
    width: 45%;
    border-radius: 10px;
    
}
.topDisp{
    display: flex;
    flex-direction: column;
}
.topDispIntroVideo{
    margin: 20px;
}
.topDispIntroVideo video{
    border-radius: 20px;
}
