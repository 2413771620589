
.CardSliderContent{
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    
}
.slider{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top:30px ;
  
    

}
.sliderContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 80%;
    
}
.navigation{
display: flex;
flex-direction: row;
flex:30;
justify-content: space-evenly;
/* align-items: end; */
justify-content: center;


}
.cardContent{
    flex: 70;
    display: flex;
    flex-direction: row;
    justify-content: center;
   
}
.cards{
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    width: 60px;
    height: 250px;
    margin: 10px;
   
   border-radius: 10px;
   box-shadow: 4px 8px 18px #00000024;
   cursor: pointer;
}
.carddescription{
    height: 250px;
    max-height: 300px;
    min-height: 100px;
    width: 400px;
    max-width: 500px;
    min-width: 100px;
    margin: 10px;
    box-shadow: 4px 8px 18px #00000024;
    border-radius: 20px;
    padding: 20px;
 
    color:black;
   
    
}
.carddescription p{
    color: black;
    
}
.cardtitle{
    display: flex;
    flex-direction: column;
width: 100%;
height: 100%;
align-items: center;
justify-content: space-evenly;

  
}
.cardtitle p{
    display: flex;
    flex-direction: row;
    justify-items: center;
    align-items: center;
margin-top: 20px;
    transform: rotate(90deg);
}
.cardtitleContent{
    width: 0;
    height: 0;
}
.cardHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    /* justify-content: center; */
}
.cardHeaderImg{
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 10px;
}
.cardHeaderDesc p{
font-size: 10px;
color: rgba(0, 0, 0, 0.593);
}
.navigation button{
    background: transparent linear-gradient(180deg, #0b3467 0%, #0a3f7f 100%) 0% 0% no-repeat padding-box;
    margin: 10px;
    border: none;
    color: white;
    border-radius: 50%;
    padding: 20px;
    outline: none;
    font-size: 10px;
}
.cardImg{
    width: 50px;
    height: 50px;
    border-radius: 100%;
   opacity: 0.7;
}
.getStarted{
   
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.getStartedContent{
    background: transparent linear-gradient(180deg, #0b3467 0%, #0a3f7f 100%) 0% 0% no-repeat padding-box;
    border-radius: 20px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
    width: 70%;
   
    
}
.getStartedContent p{
    width: 50%;
    font-size: 13px;

}
.getStarted button{
    margin: 20px;
    border-radius: 10px;
    padding: 10px 20px;
    border: none;
    color: white;
    background: transparent linear-gradient(180deg, #97CC4F 0%, #7AA146 100%) 0% 0% no-repeat padding-box;
}
.getStartedContentBg{
    background-image: url("../../../public/images/landing/bgForStatrt.png");
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 30px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}
@media only screen and (max-width: 992px) {
    .sliderContainer{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-top:30px ;
      
        
    
    }
    .getStartedContent{
        width: 100%;
    }
    .getStartedContent p{
        
        width: 100%;
    }
    .carddescription{
      
        height: 250px;
        
        width: 400px;
        max-width: 500px;
        min-width: 100px;
        margin: 10px;
        box-shadow: 4px 8px 18px #00000024;
        border-radius: 20px;
        padding: 20px;
        z-index: 6 !important; 
        color:black;
    } 
    .cards{
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        width: 400px;
        max-width: 500px;
        min-width: 80px;
        height: 100px;
        margin: 10px;
       
       border-radius: 10px;
       box-shadow: 4px 8px 18px #00000024;
       cursor: pointer;
    }
    .cardContent{
       
        display: flex;
        flex-direction: column;
        justify-content: center;
       
    }
    .cardtitle{
        display: flex;
        flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    
      
    }
    .cardtitle p{
      
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    margin-top: 20px;
        transform: rotate(0deg);
    }
  
       
}
@media only screen and (max-width: 402px) {
    .carddescription{
      
        height: 300px;
        
        width: 300px;
        max-width: 500px;
        min-width: 100px;
        margin: 10px;
        box-shadow: 4px 8px 18px #00000024;
        border-radius: 20px;
        padding: 20px;
        z-index: 6 !important; 
        color:black;
    } 
    .cards{
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        width: 300px;
        max-width: 500px;
        min-width: 80px;
        height: 100px;
        margin: 10px;
       
       border-radius: 10px;
       box-shadow: 4px 8px 18px #00000024;
       cursor: pointer;
    }
}