.TabContainer{
    width: auto;
 
}
.tabCnt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    margin-right:30px;
    width: auto;
    border-bottom: 1px solid gainsboro;
    padding: 0 30px 20px 0;
}
.tabCnt1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none;
    padding: 0;
    width: auto;
    border-bottom: 1px solid gainsboro;
    padding: 0 0 20px 0;
}
.tab{
    color: #B5B5BE; font-weight: 500;
   margin-right:30px;
    font-size: 14px;
    cursor: pointer;
    position: relative;
}
.tabActive{
    color: #7AA146;
}
.tabActive::after{
    content: '';
    height: 6px;
    width: 40%;
    border-radius: 10px;
    background-color: #7AA146;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%,50%);
}
