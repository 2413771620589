.section {

  min-height: 100vh;
  display: flex;


}
.UserSelect{
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.leftsection {
  height: auto;
  flex: 60;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('./../../public/images/Coach/login/login-bg.jpg');
  background-color: rgba(0, 0, 0, 0.667);
  background-size: cover;
  display: none;
  justify-content: start;
  align-items: center;


}
.Userhead{
  width:100%;
cursor:pointer
}
.Userhead1{
  width: 100%;
cursor: pointer;
}
.Userhead h6{
  margin-right: 10px;
  border: 1px solid #7aa146;
  border-radius: 10px;
  padding: 10px;
}
.Userhead1 h6{
margin-left: 10px;
  text-align: center;
border: 1px solid #7aa146;
border-radius: 10px;
padding: 10px;
}

.setliactive{
background-color: #7aa146;
color:white;
  border:1px solid #7aa146;
}


.Userhead h6{
  text-align: center;
 
}
.AccessDT {
  color: rgba(255, 0, 0, 0.393) !important;
}

.slidercontent {
  background: url('./../../public/images/Coach/login/dots-img.png');
  width: 800px;
  height: 450px;
  position: relative;
  display: flex;
  align-items: flex-end !important;
  justify-content: center !important;


}

.rightsection {
  height: auto;
  display: flex;
  flex: 40;
  justify-content: center;
  position: relative;
  padding: 0 0 50px 0;

}

.loginform {

  max-width: 450px;

  justify-content: center;


}

.h1 {
  color: red;
}




#loginname {
  font-weight: bolder;
  padding: 10px
}

#uname {
  color: black;
}

#desig {
  color: grey;
  font-weight: normal !important;
}

.slidertext {
  color: black;
}

.awesome {
  background-color: white;
  color: wheat;

}

.formbox {
  position: relative;
  margin-bottom: 20px;
}

.formbox label {
  padding: 0;
  margin: 0;
  font-size: 12px;
  color: #B5B5BE;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: absolute;
  top: -12px;


}

.formbox img {
  position: absolute;
  bottom: 12px;
  right: 8px;
}

.formcontrol {
  border-radius: 0;
  border: none;
  border-bottom: solid 1px #E2E2EA;
  padding: 11px 40px 7px 0;
}

.formbox input {
  width: 100%;
  transition: none;
  font-size: 15px;
  box-shadow: none;
  outline: 0;

}




.mainframe {
  display: flex;
  justify-content: center;
  align-items: center;
}

.colorpg {
  margin: 20px;
}

.slider {
  width: 400px;
  height: 250px;
  border-radius: 10px;
  margin-top: 20px;

  position: relative;
  background-color: white;

}

.slidertext {
  padding: 30px 25px 15px;

}

.profileimg {
  display: flex;
  align-items: flex-start;
  margin: 3px
}

#uname {

  font-weight: bolder;
  margin-left: 20px;
}

#desig {
  font-weight: lighter;
  color: grey;
  font-size: 13px;
  margin-left: 20px;
}

.imgsrc {
  max-width: 70px;
  margin-left: 35px;
  border-radius: 80px;
  width: 52px;
}

.arrows {
  border: none;
  margin-top: 20px
}

.imgsrc img {
  max-width: 70px;
  margin-right: 15px;
}

#btngreen {
  background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
}

#btngreencreate {
  background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  margin-top: 60px !important;




}

.instantlogin h4 {
  font-size: 14px;
  font-weight: 400;
  color: #B5B5BE;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  display: inline-block;
  background: #fff;
  padding: 5px 10px;
  position: relative;
  z-index: 1;
  border: none;
}

.btngoogle {
  background: #ea4335;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
  margin: 10px 0px 10px 0px;

}

/* forgetarea */
.icons {
  padding: 4px;

}

.formcheck {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

}

.formcheck input[type=checkbox] {
  margin-right: 10px;
  accent-color: green;
  height: 20px;
  width: 20px;
  justify-content: center;
  cursor: pointer;
}

.formcheck label {
  margin-bottom: -0.4px;
}

.btninstagram {
  background-image: linear-gradient(to bottom, #c11c9b, #d41392, #e41088, #f2177d, #fe2471);
  border-radius: 10px !important;
  width: 100%;
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  margin: 10px 0px 10px 0px;

  border: none;

}

.btnfacebook {
  background-color: #3c5a99;
  margin: 10px 0px 10px 0px;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px !important;
  text-align: center !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: 600;
  border: none;
}

.copyright img {
  width: 15px;
  opacity: 0.4;
}

.copyright {
  width: 100%;
  display: flex;
  color: grey;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;

}

.title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 30px 10px 20px 10px;

}

.title::after {
  content: "-----";
  margin-right: 10px;
  color: grey;
}

.formchecklabel {
  display: inline-flex;
  cursor: pointer;

}

/* forget */
.title::before {
  content: "-----";
  margin-left: 10px;
  color: grey;
}

.abc {
  margin-left: 400px;
}

.contain {
  background-color: white;
}

.awesome-slider div {
  background-color: white;
}

.arrows button {
  background: none;
  border: none;
  padding: 10px;
  float: left;
  outline: none;

}

/* rightsection */
.leftarrow {
  margin-top: 6px;
  border-radius: 50%;
  border: none;
  padding: 13px;

  background-color: rgba(255, 255, 255, 0.101);
}

/* formchecklabel */
.forgetarea {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;


  justify-content: space-between;

}

.forgot {
  color: rgb(0, 128, 255);
  cursor: pointer;
  padding-bottom: 10px;
  margin-top: 10px;
}

.forgot:hover {
  text-decoration: underline;
}

.inputtext {}

/* formcheck */
/* Extra small devices (phones, 600px and down) */


/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .leftsection {
    display: flex;
  }

}