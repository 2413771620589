.ProgContent{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
.ProgImg{
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    border-radius: 100%;
    margin-right: 20px;
}
.ProgContentLeft{
display: flex;
flex-direction: row;
align-items: center;
justify-content: center;
}
.ProgContentLeft div p{
color: black;
}
.ProgContentLeft div h6{
    color: black;
    font-weight: bold;
    }
.ProgContent{
   width: 100%;
}
.Allcontent{
    width: 100%;
    margin: 20px;
}
.ProgContentRight button{
    width: 100%;
    /* font-weight: bold; */
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px;
    margin-bottom: 20px;
    outline: none;
    margin-top: 20px;
}
.enrolledRecordImg{
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    border-radius: 100%;
    margin-right: 20px;
    background-color: red;
}
.enrolledRecord{
    display: flex;
    flex-direction: row;
}
.enrolledRecordData{
    color: black;
}
.enrolledRecordData p{
    color: #042643;
    font-weight: bold;
    margin-bottom: -2px;
}
.enrolledRecordPrice{
    color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 margin-top: 10px;
}

.enrolledRecordBtn{
    width: 100%;
    margin-top: 20px;
    /* font-weight: bold; */
    text-transform: capitalize !important;
    background-image: linear-gradient(to bottom, #97cc4f, #90c14d, #88b64b, #81ac48, #7aa146) !important;
    border-radius: 10px !important;
    border: none;
    color: white !important;
    padding: 10px;
   
    outline: none;
}
.coachDataModal{
    display: flex;
    flex-direction: row;
}
.ModalcoachingImg{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin: 10px;
}
.ModalContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.ModalContent p{
    color: #042643;
    margin: 3px;
    font-size: 14px;
}
.showRecord{
    margin: 20px;
    width: 100%;
}
.showRecordContent{display: flex;
flex-direction: column;}
.showRecordVideo{
height: 500px;
width: 90%;
background-color: #000;
border-radius: 20px;
}
.PaymentDetails{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    width: auto;
    justify-content: space-between;
}
.PaymentDetails p{
    color: #7aa146;
    font-weight: bold;
   

}
.matchCoachId img{
    background-color: red;
    border:4px solid #7aa146;
}
.notmatchCoachId img{
    opacity: 0.8;
}
.chapterHead{
    display: flex;
    flex-direction: row;
    width: auto;
    align-items: center;
    
   
}
.chapterHead p{
    color: black;
    margin-left: 10px;text-align: center;
}
.chapterHeadIcon{
    background-color: #EF633E;
    color: white !important;
   padding: 5px 6px;
   
    border-radius: 100%;
    text-align: center;
}
.resourcesImg{
    width: 250px;
    height: 200px;
    margin: 10px;
    border-radius:10px ;
}
.completeState{
    color:#7aa146;
}
.pendingState{
    color:#ef643ec9;
}
.ongoingState{
    color: #FFD710;
}